import * as React from 'react';
// import Lottie from 'react-lottie-player';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import animationData from '../../animations/landinganimation/data';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import customSoftwareIcon from '../assets/Custom Software Icon.svg';
import mobileAppsIcon from '../assets/mobileIcon.svg';
import webAppsIcon from '../assets/websiteIcon.svg';
import revolutionBackground from '../assets/repeatingBackground.svg';
import infoBackground from '../assets/infoBackground.svg';
import { CallToAction } from './ui/CallToAction';
import { Link } from 'react-router-dom';
import heroBlock from '../assets/heroBlock.svg';
import customSoftwareLogo from '../assets/custom_software.svg';
import mobileDevelopmentLogo from '../assets/mobileDevelopment.svg';
import webAppDevelopmentLogo from '../assets/webAppDevelopment.svg';

const useStyles = makeStyles((theme) => ({
  heroBlock: {
    backgroundColor: theme.palette.secondary.main,
    padding: '4rem 5rem 1rem',
    [theme.breakpoints.down('md')]: {
      padding: '3rem 2rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1rem',
    }
  },
  heroTextContainer: {
    flexWrap: 'nowrap',
  },
  heroTextSecondary: {
    color: theme.palette.text.secondary
  },
  btnContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  heroBlockImageContainer: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  },
  heroBlockImage: {
    width: 'calc(100% - 0.5rem)',
    [theme.breakpoints.down('md')]: {
      marginTop: '3rem'
    }
  },
  servicesBlock: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: '4rem 5rem',
    [theme.breakpoints.down('md')]: {
      padding: '3rem 2rem',
      '& div': {
        marginBottom: '2rem',
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1rem',
      '& div': {
        marginBottom: '1.25rem',
      }
    },
  },
}));


export const LandingPage = () => {
  return (
    <Grid container direction="column">
      <Grid item>
        <HeroBlock />
      </Grid>
      <Grid item>
        <ServicesBlock />
      </Grid>
      {/* <Grid item>
          <ServicesBlock />
        </Grid>
        <Grid item>
          <MobileDevBlock />
        </Grid>
        <Grid item>
          <WebDevBlock />
        </Grid>
        <Grid item>
          <RevolutionBlock />
        </Grid>
        <Grid item>
          <InformationBlock />
        </Grid>
        <Grid item>
          <CallToAction />
        </Grid> */}
    </Grid>
  );
}

const HeroBlock = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="space-between" className={classes.heroBlock}>
      <Grid item container className={classes.heroTextContainer} direction="column" xl={8} lg={8} md={7} spacing={2}>
        <Grid item>
          <Typography variant="h1" align="left" color='textPrimary'>
            <span>Bringing </span><span className={classes.heroTextSecondary}>you </span>
            <span>closer to your </span>
            <span className={classes.heroTextSecondary}>digital identity</span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum.</Typography>
        </Grid>
        <Grid item container className={classes.btnContainer} spacing={2}>
          <Grid item>
            <Button
              component={Link}
              to="/estimate"
              variant="contained"
              color='primary'
            >
              Free estimate
            </Button>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to="/revolution"
              variant="outlined"
              color='secondary'
            >
              Learn more
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4} md={5} className={classes.heroBlockImageContainer}>
        <img src={heroBlock} alt="tech logo" className={classes.heroBlockImage} />
      </Grid>
    </Grid >
  );
};

const ServicesBlock = () => {
  const classes = useStyles();

  return (
    <Grid container justify='space-around' className={classes.servicesBlock}>
      <Grid item container xl={4} lg={4} md={4} direction="column" alignItems='center' spacing={1}>
        <Grid item><img src={customSoftwareLogo} alt="person sitting next to laptop" /></Grid>
        <Grid item><Typography variant='h5'>Custom Software Development</Typography></Grid>
        <Grid item><Typography variant='subtitle2' color='secondary' align='center'>Save Energy. Save Time. Save Money. <br />Complete digital solutions, from investigation to celebration</Typography></Grid>
        <Grid item>
          <Button component={Link} to="/custom-software" variant="outlined" color='secondary'>Learn more</Button>
        </Grid>
      </Grid>
      <Grid item container xl={4} lg={4} md={4} direction="column" alignItems='center' spacing={1}>
        <Grid item><img src={mobileDevelopmentLogo} alt="person looking at mobile device" /></Grid>
        <Grid item><Typography variant='h5'>IOS/Android App development</Typography></Grid>
        <Grid item><Typography variant='subtitle2' color='secondary' align='center'>Extend Functionality. Extend Access. <br />Increase Engagement. <br />Increase your web experience or create a standalone app with either mobile platform</Typography></Grid>
        <Grid item>
          <Button component={Link} to="/mobile-apps" variant="outlined" color='secondary'>Learn more</Button>
        </Grid>
      </Grid>
      <Grid item container xl={4} lg={4} md={4} direction="column" alignItems='center' spacing={1}>
        <Grid item><img src={webAppDevelopmentLogo} alt="person looking at code block" /></Grid>
        <Grid item><Typography variant='h5'>Web App Development</Typography></Grid>
        <Grid item><Typography variant='subtitle2' color='secondary' align='center'>Reach more. Discover more. Sell more.<br />Optimized for Search Engines. <br />Built for speed</Typography></Grid>
        <Grid item>
          <Button component={Link} to="/web-apps" variant="outlined" color='secondary'>Learn more</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

// const MobileDevBlock = () => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
//   return (
//     <Grid
//       container
//       justify={matchesSm ? 'center' : 'flex-end'}
//       className={classes.servicesContainer}
//     >
//       <Grid item style={{ textAlign: matchesSm ? 'center' : undefined }}>
//         <Typography variant="h4">iOS/Android app development</Typography>
//         <Typography variant="subtitle1" className={classes.subtitle}>Extend Functionality. Extend Acces. Increase Engagement</Typography>
//         <Typography variant="subtitle1">Increase your web experience or create a standalone app{matchesSm ? null : <br />} with either mobile platform</Typography>
//         <Button
//           component={Link}
//           to="/mobile-apps"
//           variant="outlined"
//           className={classes.learnMoreButton}
//           style={{ marginBottom: matchesSm ? '2em' : 0 }}
//         >
//           Learn more
//         </Button>
//       </Grid>
//       <Grid item style={{ marginRight: matchesSm ? 0 : '5em' }}>
//         <img alt="mobile phone icon" src={mobileAppsIcon} className={classes.imgIcon} />
//       </Grid>
//     </Grid>
//   );
// };

// const WebDevBlock = () => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
//   return (
//     <Grid
//       container
//       justify={matchesSm ? 'center' : undefined}
//       className={classes.servicesContainer}
//     >
//       <Grid item style={{ marginLeft: matchesSm ? 0 : '5em', textAlign: matchesSm ? 'center' : undefined }}>
//         <Typography variant="h4">Web App Development</Typography>
//         <Typography variant="subtitle1" className={classes.subtitle}>Reach more. Discover more. Sell more. </Typography>
//         <Typography variant="subtitle1">Optimized for Search Engines, built for speed.</Typography>
//         <Button
//           component={Link}
//           to="/web-apps"
//           variant="outlined"
//           className={classes.learnMoreButton}
//           style={{ marginBottom: matchesSm ? '2em' : 0 }}
//         >
//           Learn more
//         </Button>
//       </Grid>
//       <Grid item>
//         <img alt="web apps icon" src={webAppsIcon} className={classes.imgIcon} />
//       </Grid>
//     </Grid>
//   );
// };

// const RevolutionBlock = () => {
//   const classes = useStyles();
//   return (
//     <Grid container alignItems="center" justify="center" style={{ height: '100em', marginTop: '10em' }}>
//       <Card className={classes.revolutionCard}>
//         <CardContent>
//           <Grid container direction="column" style={{ textAlign: 'center' }}>
//             <Grid item>
//               <Typography variant="h3">
//                 The Revolution
//               </Typography>
//             </Grid>
//             <Grid item>
//               <Typography variant="subtitle1" gutterBottom>
//                 Visionary insights coupled with cutting-edge technology is a recipe for revolution.
//               </Typography>
//               <Button
//                 component={Link}
//                 to="/revolution"
//                 variant="outlined"
//                 className={classes.learnMoreButton}
//               >
//                 Learn more
//               </Button>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//       <div className={classes.revolutionBackground}></div>
//     </Grid>
//   );
// }

// const InformationBlock = () => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
//   const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
//   return (
//     <Grid container alignItems="center" style={{ height: '80rem' }} className={classes.infoBackground}>
//       <Grid
//         item
//         container
//         style={{ textAlign: matchesXs ? 'center' : 'inherit' }}
//         direction={matchesXs ? 'column' : 'row'}
//       >
//         <Grid item sm style={{ marginLeft: matchesXs ? 0 : matchesSm ? '2em' : '5em' }}>
//           <Grid container direction="column" style={{ marginBottom: matchesXs ? '10em' : 0 }}>
//             <Grid item >
//               <Typography variant="h2" style={{ color: 'white' }}>
//                 About us
//               </Typography>
//               <Typography variant="subtitle2">
//                 Let's get personal
//               </Typography>
//               <Grid item>
//                 <Button
//                   component={Link}
//                   to="/about"
//                   variant="outlined"
//                   className={classes.learnMoreButton}
//                   style={{ color: 'white', borderColor: 'white' }}
//                 >
//                   Learn more
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid >
//         <Grid item sm style={{ marginRight: matchesXs ? 0 : matchesSm ? '2em' : '5em', textAlign: matchesXs ? 'center' : 'right' }}>
//           <Grid container direction="column">
//             <Grid item>
//               <Typography variant="h2" style={{ color: 'white' }}>
//                 Contact us
//               </Typography>
//               <Typography variant="subtitle2">
//                 Say hello
//               </Typography>
//               <Grid item>
//                 <Button
//                   component={Link}
//                   to="/contact"
//                   variant="outlined"
//                   className={classes.learnMoreButton}
//                   style={{ color: 'white', borderColor: 'white' }}
//                 >
//                   Learn more
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid >
//       </Grid>
//     </Grid>
//   )
// }
