import * as  React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Header } from './ui/Header';
import { theme } from '../theme';
import { flatRoutes } from '../routes';
import { Footer } from './ui/Footer';
import { CssBaseline } from '@material-ui/core';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Header />
        <Switch>
          {flatRoutes.map((route) => <Route {...route} key={route.label} />)}
        </Switch>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
