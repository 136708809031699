import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import customSoftwareLogo from '../assets/custom_software.svg';
import mobileDevelopmentLogo from '../assets/mobileDevelopment.svg';
import webAppDevelopmentLogo from '../assets/webAppDevelopment.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});


const defaultQuestions = [{
  id: 1,
  title: "Which service are you interested in?",
  active: true,
  options: [{
    id: 1,
    title: 'Custom Software Development',
    subtitle: null,
    icon: customSoftwareLogo,
    iconAlt: 'person sitting next to laptop',
    selected: false,
    cost: 0,
  }, {
    id: 2,
    title: 'IOS/Android App Development',
    subtitle: null,
    icon: mobileDevelopmentLogo,
    iconAlt: 'person looking at mobile device',
    selected: false,
    cost: 0,
  }, {
    id: 3,
    title: 'Web App Development',
    subtitle: null,
    icon: webAppDevelopmentLogo,
    iconAlt: 'person looking at code block',
    selected: false,
    cost: 0,
  }]
}];

const softwareQuestions = [
  { ...defaultQuestions[0], active: false },
  {
    id: 2,
    title: "Which platforms do you need supported?",
    subtitle: "Select all that apply.",
    options: [
      {
        id: 1,
        title: "Web Application",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "computer outline",
        selected: false,
        cost: 100
      },
      {
        id: 2,
        title: "iOS Application",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of iphone",
        selected: false,
        cost: 100
      },
      {
        id: 3,
        title: "Android Application",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outlines of android phone",
        selected: false,
        cost: 100
      }
    ],
    active: true
  },
  {
    id: 3,
    title: "Which features do you expect to use?",
    subtitle: "Select all that apply.",
    options: [
      {
        id: 1,
        title: "Photo/Video",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "camera outline",
        selected: false,
        cost: 25
      },
      {
        id: 2,
        title: "GPS",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "gps pin",
        selected: false,
        cost: 25
      },
      {
        id: 3,
        title: "File Transfer",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of cloud with arrow pointing up",
        selected: false,
        cost: 25
      }
    ],
    active: false
  },
  {
    id: 4,
    title: "Which features do you expect to use?",
    subtitle: "Select all that apply.",
    options: [
      {
        id: 1,
        title: "Users/Authentication",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of a person with a plus sign",
        selected: false,
        cost: 25
      },
      {
        id: 2,
        title: "Biometrics",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "fingerprint",
        selected: false,
        cost: 25
      },
      {
        id: 3,
        title: "Push Notifications",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of a bell",
        selected: false,
        cost: 25
      }
    ],
    active: false
  },
  {
    id: 5,
    title: "What type of custom features do you expect to need?",
    subtitle: "Select one.",
    options: [
      {
        id: 1,
        title: "Low Complexity",
        subtitle: "(Informational)",
        icon: webAppDevelopmentLogo,
        iconAlt: "'i' inside a circle",
        selected: false,
        cost: 25
      },
      {
        id: 2,
        title: "Medium Complexity",
        subtitle: "(Interactive, Customizable, Realtime)",
        icon: webAppDevelopmentLogo,
        iconAlt: "two toggle switches",
        selected: false,
        cost: 50
      },
      {
        id: 3,
        title: "High Complexity",
        subtitle: "(Data Modeling and Computation)",
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of line graph",
        selected: false,
        cost: 100
      }
    ],
    active: false
  },
  {
    id: 6,
    title: "How many users do you expect?",
    subtitle: "Select one.",
    options: [
      {
        id: 1,
        title: "0-10",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "person outline",
        selected: false,
        cost: 1
      },
      {
        id: 2,
        title: "10-100",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of two people",
        selected: false,
        cost: 1.25
      },
      {
        id: 3,
        title: "100+",
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of three people",
        selected: false,
        cost: 1.5
      }
    ],
    active: false
  }
];


const websiteQuestions = [
  { ...defaultQuestions[0], active: false },
  {
    id: 2,
    title: "Which type of website are you wanting?",
    subtitle: "Select one.",
    options: [
      {
        id: 1,
        title: "Basic",
        subtitle: "(Informational)",
        icon: webAppDevelopmentLogo,
        iconAlt: "person outline",
        selected: false,
        cost: 100
      },
      {
        id: 2,
        title: "Interactive",
        subtitle: "(Users, API's, Messaging)",
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of two people",
        selected: false,
        cost: 200
      },
      {
        id: 3,
        title: "E-Commerce",
        subtitle: "(Sales)",
        icon: webAppDevelopmentLogo,
        iconAlt: "outline of three people",
        selected: false,
        cost: 250
      }
    ],
    active: true
  }
];

export const Estimate = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return <Grid container>
    <Grid item container direction='column' lg style={{ marginTop: '4em', marginLeft: '1.5em', marginBottom: '3em' }}>
      <Grid item>
        <Typography variant='h2'>Estimate</Typography>
      </Grid>
      <Grid item>
        Some picture here
      </Grid>
    </Grid>
    <Grid item container direction='column' alignItems='center' lg style={{ marginTop: '4em', marginLeft: '1.5em', marginBottom: '3em' }} spacing={6}>
      {defaultQuestions.filter(q => q.active).map(q => (
        <React.Fragment key={q.id}>
          <Grid item>
            <Typography variant='h4' align='center' gutterBottom>{q.title}</Typography>
          </Grid>
          <Typography variant='body1' align='center' style={{ marginBottom: '2.5em' }} gutterBottom>
            {q.subtitle}
          </Typography>
          <Grid item container justify='center'>
            {q.options.map(opt => (
              <Grid item container direction="column" md key={opt.id}>
                <Grid item>
                  <Typography variant='h6' align="center">{opt.title}</Typography>
                </Grid>
                <Grid item>
                  <img src={opt.icon} alt={opt.iconAlt} />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <MobileStepper
              variant="progress"
              steps={6}
              position="static"
              activeStep={activeStep}
              className={classes.root}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                  Next
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Back
                </Button>
              }
            />
          </Grid>
        </React.Fragment>
      ))}
      {/* <Grid item container justify='center'>
        <Grid item container direction="column" md>
          <Grid item>
            <Typography variant='h6' align="center">Custom Software Development</Typography>
          </Grid>
          <Grid item>
            <img src={customSoftwareLogo} alt="person sitting next to laptop" />
          </Grid>
        </Grid>
        <Grid item container direction="column" md>
          <Grid item>
            <Typography variant='h6' align="center" >IOS/Android App Development</Typography>
          </Grid>
          <Grid item>
            <img src={mobileDevelopmentLogo} alt="person looking at mobile device" />
          </Grid>
        </Grid>
        <Grid item container direction="column" md>
          <Grid item>
            <Typography variant='h6' align="center">Web App Development</Typography>
          </Grid>
          <Grid item>
            <img src={webAppDevelopmentLogo} alt="person looking at code block" />
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  </Grid>
}
