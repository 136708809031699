import * as React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import background from '../assets/background.jpg';
import mobileBackground from '../assets/mobileBackground.jpg';
import phoneIcon from '../assets/phone.svg';
import emailIcon from '../assets/email.svg';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '60em',
    paddingBottom: '10em',
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${mobileBackground})`,
    }
  },
  learnMoreButton: {
    borderColor: theme.palette.common.blue,
    color: theme.palette.common.blue,
    borderWidth: 2,
    borderRadius: 50,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '0.9rem',
    height: 45,
    width: 145,
  },
  estimateButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 80,
    width: 205,
    backgroundColor: theme.palette.common.orange,
    fontSize: '1.5rem',
    marginRight: '5em',
    marginLeft: '2em',
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: '2em',
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  sendButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 45,
    width: 245,
    fontSize: '1rem',
    backgroundColor: theme.palette.common.orange,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      width: 225,
      height: 40,
    }
  },
  message: {
    marginTop: '5em',
    borderRadius: 5,
    border: `2px solid ${theme.palette.common.blue}`,
  }
}));

export const Contact = () => {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailHelper, setEmailHelper] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [phoneHelper, setPhoneHelper] = React.useState('');
  const [message, setMessage] = React.useState('');
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  const onChangeHandler = (e) => {
    let valid;
    switch (e.target.id) {
      case 'email':
        setEmail(e.target.value);
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value);
        if (!valid) {
          setEmailHelper('Invalid email')
        } else {
          setEmailHelper('');
        }
        break;
      case 'phone':
        setPhone(e.target.value);
        valid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(e.target.value);
        if (!valid) {
          setPhoneHelper('Invalid phone number')
        } else {
          setPhoneHelper('');
        }
        break;
      default:
        break;
    }
  }

  return (
    <Grid container>
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ margin: matchesSm ? '1em 0 5em' : matchesMd ? '5em 0' : 0 }}
        lg={4}
        xl={3}
      >
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="h2"
                style={{ lineHeight: 1 }}
                align={matchesMd ? "center" : undefined}
              >
                Contact us
              </Typography>
              <Typography
                variant="body1"
                style={{ color: theme.palette.common.blue }}
                align={matchesMd ? "center" : undefined}
              >
                We're waiting.
              </Typography>
            </Grid>
            <Grid item container style={{ marginTop: '2em' }}>
              <Grid item>
                <img src={phoneIcon} alt="phone" style={{ marginRight: '0.5em' }} />
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ color: theme.palette.common.blue, fontSize: '1rem' }}>
                  <a href="tel:55555555555" style={{ textDecoration: "none", color: "inherit" }}>555555555</a></Typography>
              </Grid>
            </Grid>
            <Grid item container style={{ marginBottom: '2em' }}>
              <Grid item>
                <img src={emailIcon} alt="envelope" style={{ marginRight: '0.5em', verticalAlign: 'bottom' }} />
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ color: theme.palette.common.blue, fontSize: '1rem' }}>
                  <a href="mailto:alex.serbang+akhetdigital@gmail.com" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>me@me.me</a></Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" style={{ maxWidth: '20em' }}>
              <Grid item style={{ marginBottom: '0.5em' }}>
                <TextField
                  label="Name"
                  id="name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item style={{ marginBottom: '0.5em' }}>
                <TextField
                  label="Email"
                  id="email"
                  fullWidth
                  value={email}
                  onChange={onChangeHandler}
                  error={!!emailHelper}
                  helperText={emailHelper}
                />
              </Grid>
              <Grid item style={{ marginBottom: '0.5em' }}>
                <TextField
                  label="Phone"
                  id="phone"
                  fullWidth
                  value={phone}
                  onChange={onChangeHandler}
                  error={!!phoneHelper}
                  helperText={phoneHelper}
                />
              </Grid>
            </Grid>
            <Grid item style={{ maxWidth: '20em' }}>
              <TextField
                InputProps={{ disableUnderline: true }}
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                fullWidth
                rows={10}
                className={classes.message}
              />
            </Grid>
            <Grid item container justify="center" style={{ marginTop: '2em' }}>
              <Button
                variant="contained"
                className={classes.sendButton}
                disabled={!name || !message || !!emailHelper || !!phoneHelper}
              >
                Send message
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        className={classes.background}
        direction={matchesMd ? "column" : "row"}
        justify={matchesMd ? "center" : undefined}
        lg={8}
        xl={9}
      >
        <Grid item style={{ marginLeft: matchesMd ? 0 : '3em', textAlign: matchesMd ? 'center' : 'inherit' }}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h2" align={matchesMd ? "center" : undefined}>
                Simple Software.<br />Revolutionary results.
              </Typography>
              <Typography variant="subtitle2" align={matchesMd ? "center" : undefined} style={{ fontSize: '1.5rem' }}>
                Take advantage of the 21st century
              </Typography>
              <Grid item container justify={matchesMd ? "center" : undefined}>
                <Button
                  variant="outlined"
                  className={classes.learnMoreButton}
                  component={Link}
                  to="/revolution"
                >
                  Learn more
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="contained" className={classes.estimateButton}>Free estimate</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
