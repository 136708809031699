import { keyBy } from 'lodash';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../routes';


export const useActiveTab = () => {
  const routesByPath = React.useMemo(
    () => keyBy(routes, 'path'),
    []);
  const initialRoute = routesByPath[window.location.pathname];
  const [activeTab, setActiveTab] = React.useState(initialRoute?.tabId);

  const { pathname } = useLocation();
  React.useEffect(
    () => {
      if (routesByPath[pathname]) {
        setActiveTab(routesByPath[pathname].tabId)
      }
    },
    [routesByPath, pathname]);

  return {
    routesByPath,
    activeTab,
  }
};
